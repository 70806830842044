<template>
	<div style="max-width: 960px">
		<v-container fluid>
			<v-row>
				<v-col>
					<p>
						{{ $t('text.application-form-description') }}
					</p>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<fieldset class="talentics-fieldset">
						<legend>{{ $t('text.personal-information') }}</legend>
						<v-container style="padding-top: 0">
							<div v-for="item in formItems" :key="item.value">
								<v-row>
									<v-col
										align-self="center"
										:style="`${item.active ? '' : 'opacity: .5'}`"
									>
										{{ item.label }}
										<span
											v-if="item.mandatory"
											style="color: red; font-size: 0.85rem"
											>({{ $t('text.mandatory') }})</span
										>
									</v-col>

									<v-col class="disp-flex flex-end" cols="auto">
										<v-btn
											v-if="item.mandatory"
											:style="`${item.active ? '' : 'opacity: .5'}`"
											text
											icon
										>
											<v-icon>{{
												item.required ? 'star' : 'star_outline'
											}}</v-icon>
										</v-btn>
										<v-btn
											v-else
											:style="`${item.active ? '' : 'opacity: .5'}`"
											text
											icon
											@click="item.required = !item.required"
										>
											<v-icon>{{
												item.required ? 'star' : 'star_outline'
											}}</v-icon>
										</v-btn>
										<v-switch
											:readonly="item.mandatory"
											v-model="item.active"
											dense
											hide-details
											class="apply-form-switch"
											color="success"
											inset
										></v-switch>
									</v-col>
								</v-row>
								<v-divider></v-divider>
							</div>
						</v-container>
					</fieldset>
				</v-col>
			</v-row>

			<v-row class="mt-4">
				<v-col>
					<AdditionalApplicationFormQuestions
						v-model="linkQuestions"
						:label="$t('text.link-questions')"
					></AdditionalApplicationFormQuestions>
				</v-col>
			</v-row>

			<v-row class="mt-4">
				<v-col>
          <CheckboxesEditor
            v-model="checkboxes"
          ></CheckboxesEditor>
				</v-col>
			</v-row>

			<v-row class="mt-4">
				<v-col>
					<v-btn color="primary" @click="handleSubmit">{{
						$t('button.save')
					}}</v-btn>
					<span class="caption ml-2">{{
						$t('text.affect-all-job-postings')
					}}</span>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import AdditionalApplicationFormQuestions from '@/components/shared/additionalApplicationFormQuestions/AdditionalApplicationFormQuestions';
import CheckboxesEditor from '@/components/shared/checkboxesEditor/CheckboxesEditor';

export default {
	name: 'ApplicationForm',
	computed: {
		...mapState({
			company: state => state.company.company,
			applicationForm: state => state.company.applicationForm,
		}),
	},
	components: {
		AdditionalApplicationFormQuestions,
    CheckboxesEditor,
	},
	data() {
		return {
			newFormItem: null,
			formItems: [
				{
					propName: 'linkedin_profile',
					label: this.$t('label.linkedin-profile'),
					type: 'text',
					active: false,
					required: false,
					mandatory: false,
				},
				{
					propName: 'file',
					label: this.$t('label.resume'),
					type: 'file',
					active: true,
					required: false,
					mandatory: false,
				},
				{
					propName: 'name',
					label: this.$t('label.full-name'),
					type: 'text',
					active: true,
					required: false,
					mandatory: true,
				},
				{
					propName: 'email',
					label: this.$t('label.email'),
					type: 'email',
					active: true,
					required: false,
					mandatory: true,
				},
				{
					propName: 'phone',
					label: this.$t('label.phone'),
					type: 'text',
					active: true,
					required: false,
					mandatory: false,
				},
				{
					propName: 'current_company',
					label: this.$t('label.current-company'),
					type: 'text',
					active: false,
					required: false,
					mandatory: false,
				},
				{
					propName: 'cover_letter',
					label: this.$t('label.cover-letter'),
					type: 'textarea',
					active: false,
					required: false,
					mandatory: false,
				},
			],
			linkQuestions: [],
			checkboxes: [],
		};
	},
	methods: {
		handleSubmit() {
			const { formItems, linkQuestions, checkboxes } = this;
			const application_form_json_schema = {
				form_items: formItems.map(
					({ propName, label, type, required, active }) => ({
						propName,
						label,
						type,
						required,
						active,
					}),
				),
				link_questions: linkQuestions.map(
					({ propName, label, type, required, options, sensitive }) => ({
						propName,
						label,
						type,
						required,
						options,
						sensitive,
					}),
				),
				checkboxes: checkboxes.map(({ label, html, required }) => ({
					label,
					html,
					required,
				})),
			};
			this.$store.dispatch('company/put', {
				application_form_json_schema,
			});
		},

		handleInitializeApplicationForm() {
			const { form_items, link_questions, checkboxes } = this.applicationForm;
			if (form_items) {
				this.formItems = this.formItems.map(formItem => {
					const relatedFormItem = form_items.find(
						item => item.propName === formItem.propName,
					);
					if (relatedFormItem) {
						const { active, required } = relatedFormItem;
						return {
							...formItem,
							active,
							required,
						};
					}
					return formItem;
				});
			}
			if (link_questions) {
				this.linkQuestions = [...link_questions];
			}
			if (checkboxes) {
				this.checkboxes = checkboxes;
			}
		},
	},
	watch: {
		applicationForm: {
			handler: function (newVal, oldVal) {
				if (newVal !== oldVal) {
					this.handleInitializeApplicationForm();
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>

<style>
.disp-flex {
	display: flex;
}
.flex-end {
	justify-content: flex-end;
}
.apply-form-switch {
	margin-top: 0;
	align-self: center;
}

.all-scroll {
	cursor: all-scroll;
}
</style>
