import axios from 'axios';

export default {
	activities: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/activities/${id}`, { params });
			}
			return axios.get('/v1/hire-app/activities', { params });
		},
	},
	agent: {
		company: {
			get(params) {
				return axios.get('/v1/hire-app/agent/company', { params });
			},
			post(params) {
				return axios.post(`/v1/hire-app/agent/company`, params);
			},
		},
	},
	applicants: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/applicants/${id}`, { params });
			}
			return axios.get('/v1/hire-app/applicants', { params });
		},
		post({ id, ...params }) {
			if (id) {
				return axios.post(`/v1/hire-app/applicants/${id}`, params);
			}
			return axios.post('/v1/hire-app/applicants', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/applicants/${id}`, params);
			}
			return axios.put('/v1/hire-app/applicants', params);
		},
		counters: {
			get(params) {
				return axios.get('/v1/hire-app/applicants/counters', {
					params,
				});
			},
			interview: {
				get(params) {
					return axios.get('/v1/hire-app/applicants/counters/interview', {
						params,
					});
				},
			},
			archive: {
				get(params) {
					return axios.get('/v1/hire-app/applicants/counters/archive', {
						params,
					});
				},
			},
			lead: {
				get(params) {
					return axios.get('/v1/hire-app/applicants/counters/lead', {
						params,
					});
				},
			},
		},
		checkEmail: {
			get(params) {
				return axios.get('/v1/hire-app/applicants/check-email', {
					params,
				});
			},
		},
	},
	approvals: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/approvals/${id}`, { params });
			}
			return axios.get('/v1/hire-app/approvals', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/approvals', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/approvals/${id}`, params);
			}
			return axios.put('/v1/hire-app/approvals', params);
		},
	},
	approvalTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/approval-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/approval-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/approval-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/approval-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/approval-templates', params);
		},
	},
	authUser: {
		post(params) {
			return axios.post('/v1/hire-app/auth-user', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/auth-user', params);
		},
		sendSignInEmail: {
			post(params) {
				return axios.post('/v1/hire-app/auth-user/send-signin-email', params);
			},
		},
		syncGoogleMail(params) {
			return axios.get('/v1/google/auth?service=mail', params);
		},
		revokeGoogleMail(params) {
			return axios.post('v1/google/auth/revoke?service=mail', params);
		},
	},
	bulkImport: {
		post(params) {
			return axios.post('/v1/hire-app/bulk-import', params);
		},
	},
	events: {
		get(params) {
			return axios.get('/v1/hire-app/calendar/events', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/calendar/events', params);
		},
	},
	assessments: {
		get({ candidate_id, ...params }) {
			return axios.get(`v1/spica/hire-app/candidates/${candidate_id}/links`, {
				params,
			});
		},
		post(params) {
			return axios.post('/v1/spica/hire-app/link', params);
		},
		getResults({ candidateId, spicaLinkId }) {
			return axios.get(
				`v1/spica/hire-app/candidates/${candidateId}/links/${spicaLinkId}/results`,
			);
		},
		getAssessmentReport({ candidateId, spicaLinkId, moduleId }) {
			if (moduleId) {
				return axios.get(
					`v1/spica/hire-app/candidates/${candidateId}/links/${spicaLinkId}/report?reportModule=${moduleId}`,
				);
			} else
				return axios.get(
					`v1/spica/hire-app/candidates/${candidateId}/links/${spicaLinkId}/report`,
				);
		},
	},
	candidates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/candidates/${id}`, { params });
			}
			return axios.get('/v1/hire-app/candidates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/candidates', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/candidates', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/candidates/${id}`, params);
			}
			return axios.delete('/v1/hire-app/candidates', params);
		},
		pinCandidate(candidateId) {
			return axios.put(`/v1/hire-app/candidates/${candidateId}/pin`);
		},
		unpinCandidate(candidateId) {
			return axios.put(`/v1/hire-app/candidates/${candidateId}/unpin`);
		},
		applications: {
			get({ id, application_id, ...params }) {
				if (id) {
					if (application_id) {
						return axios.get(
							`/v1/hire-app/candidates/${id}/applications/${application_id}`,
							{ params },
						);
					}
					return axios.get(`/v1/hire-app/candidates/${id}/applications`, {
						params,
					});
				}
				return axios.get('/v1/hire-app/candidates/applications', {
					params,
				});
			},
			post({ id, application_id, ...params }) {
				if (id) {
					if (application_id) {
						return axios.post(
							`/v1/hire-app/candidates/${id}/applications/${application_id}`,
							params,
						);
					}
					return axios.post(
						`/v1/hire-app/candidates/${id}/applications`,
						params,
					);
				}
				return axios.post('/v1/hire-app/candidates/applications', params);
			},
			put({ id, application_id, ...params }) {
				if (id) {
					if (application_id) {
						return axios.put(
							`/v1/hire-app/candidates/${id}/applications/${application_id}`,
							params,
						);
					}
					return axios.put(
						`/v1/hire-app/candidates/${id}/applications`,
						params,
					);
				}
				return axios.put('/v1/hire-app/candidates/applications', params);
			},
			durations: {
				interview: {
					get({ candidate_id, application_id, ...params }) {
						return axios.get(
							`/v1/hire-app/candidates/${candidate_id}/applications/${application_id}/durations/interview`,
							params,
						);
					},
				},
			},
		},
	},
	checkToken: {
		get() {
			return axios.get('/v1/hire-app/check-token');
		},
	},
	chromeExtensionToken: {
		put(params) {
			return axios.put('/v1/hire-app/chrome-extension-token', params);
		},
	},
	chromeExtensionVersions: {
		get({ params }) {
			return axios.get('/v1/hire-app/chrome-extension-versions', {
				params,
			});
		},
	},
	code: {
		put(params) {
			return axios.put('/v1/hire-app/code', params);
		},
	},
	comments: {
		get(params) {
			return axios.get('/v1/hire-app/comments', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/comments', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/comments', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/comments/${id}`, { params });
			}
			return axios.delete('/v1/hire-app/comments', { params });
		},
	},
	company: {
		get(params) {
			return axios.get('/v1/hire-app/company', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/company', params);
		},
		cdnFiles: {
			get(params) {
				return axios.get('/v1/hire-app/company/cdn-files', { params });
			},
			post(params) {
				return axios.post('/v1/hire-app/company/cdn-files', params);
			},
		},
		confirmationEmail: {
			get(params) {
				return axios.get('/v1/hire-app/company/confirmation-email', {
					params,
				});
			},
			put(params) {
				return axios.put('/v1/hire-app/company/confirmation-email', params);
			},
		},
		customBlocks: {
			get(params) {
				return axios.get('/v1/hire-app/company/custom-blocks', {
					params,
				});
			},
			put(params) {
				return axios.put('/v1/hire-app/company/custom-blocks', params);
			},
		},
		resourceUsage: {
			companyCredits: {
				get(params) {
					return axios.get(
						'/v1/hire-app/company/resource-usage/company-credits',
						{ params },
					);
				},
			},
			resumeParsing: {
				get(params) {
					return axios.get(
						'/v1/hire-app/company/resource-usage/resume-parsing',
						{ params },
					);
				},
			},
			scoreMatch: {
				get(params) {
					return axios.get('/v1/hire-app/company/resource-usage/score-match', {
						params,
					});
				},
			},
		},
	},
	customCareerPages: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/custom-career-pages/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/custom-career-pages', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/custom-career-pages', params);
		},
		post(params) {
			return axios.post('/v1/hire-app/custom-career-pages', params);
		},
	},
	departments: {
		get(params) {
			return axios.get('/v1/hire-app/departments', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/departments', params);
		},
		post(params) {
			return axios.post('/v1/hire-app/departments', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/departments/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/departments', { params });
		},
	},
	emails: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/emails/${id}`, { params });
			}
			return axios.get('/v1/hire-app/emails', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/emails', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/emails/${id}`, params);
			}
			return axios.put('/v1/hire-app/emails', params);
		},
	},
	emailLayouts: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/email-layouts/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/email-layouts', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/email-layouts', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/email-layouts/${id}`, params);
			}
			return axios.put('/v1/hire-app/email-layouts', params);
		},
	},
	emailTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/email-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/email-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/email-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/email-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/email-templates', params);
		},
	},
	eventTypes: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/event-types/${id}`, { params });
			}
			return axios.get('/v1/hire-app/event-types', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/event-types', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/event-types', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/event-types/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/event-types', { params });
		},
	},
	exportableReports: {
		post(params) {
			return axios.post(`/v1/hire-app/exportable-reports`, params);
		},
	},
	feedbacks: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/feedbacks/${id}`, { params });
			}
			return axios.get('/v1/hire-app/feedbacks', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/feedbacks', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/feedbacks/${id}`, params);
			}
			return axios.put('/v1/hire-app/feedbacks', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/feedbacks/${id}`, { params });
			}
			return axios.delete('/v1/hire-app/feedbacks', { params });
		},
		shareViaEmail: {
			post(params) {
				return axios.post('/v1/hire-app/feedbacks/share-via-email', params);
			},
		},
		incomplete: {
			get(params) {
				return axios.get('/v1/hire-app/feedbacks/incomplete', {
					params,
				});
			},
		},
	},
	feedbackTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/feedback-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/feedback-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/feedback-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/feedback-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/feedback-templates', params);
		},
	},
	files: {
		get({ id, parseResume }) {
			return axios.get(
				`/v1/hire-app/files/${id}${parseResume ? '?parse_resume=1' : ''}`,
			);
		},
		base64: {
			get({ id }) {
				return axios.get(`/v1/hire-app/files/base64/${id}`);
			},
		},
		parse: {
			post({ id, ...params }) {
				return axios.post(`/v1/hire-app/files/${id}/parse`, params);
			},
		},
	},
	formRulesets: {
		get(params) {
			return axios.get('/v1/hire-app/form-rulesets', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/form-rulesets', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/form-rulesets', params);
		},
	},
	formTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/form-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/form-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/form-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/form-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/form-templates', params);
		},
	},
	forms: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/forms/${id}`, { params });
			}
			return axios.get('/v1/hire-app/forms', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/forms', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/forms/${id}`, params);
			}
			return axios.put('/v1/hire-app/forms', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/forms/${id}`, { params });
			}
			return axios.delete('/v1/hire-app/forms', { params });
		},
		shareViaEmail: {
			post(params) {
				return axios.post('/v1/hire-app/forms/share-via-email', params);
			},
		},
		incomplete: {
			get(params) {
				return axios.get('/v1/hire-app/forms/incomplete', { params });
			},
		},
	},
	hiredCandidates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/hired-candidates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/hired-candidates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/hired-candidates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/hired-candidates/${id}`, {
					id,
					...params,
				});
			}
			return axios.put('/v1/hire-app/hired-candidates', params);
		},
	},
	jobs: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/jobs/${id}`, { params });
			}
			return axios.get('/v1/hire-app/jobs', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/jobs', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/jobs', params);
		},
	},
	hiringPlan: {
		get({ id }) {
			return axios.get(`/v1/hire-app/hiring-plan/${id}`);
		},
		put({ id, ...params }) {
			return axios.put(`/v1/hire-app/hiring-plan/${id}`, params);
		},
		post(params) {
			return axios.post(`/v1/hire-app/hiring-plan`, params);
		},
		delete({ id }) {
			return axios.delete(`/v1/hire-app/hiring-plan/${id}`);
		},
	},
	hiringPlanItem: {
		get({ hiring_plan_id }) {
			return axios.get(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item`);
		},
		put({ hiring_plan_id, hiring_plan_item_id, ...params }) {
			return axios.put(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item/${hiring_plan_item_id}`, params);
		},
		post({ hiring_plan_id, ...params }) {
			return axios.post(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item`, params);
		},
		delete({ hiring_plan_id, hiring_plan_item_id }) {
			return axios.delete(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item/${hiring_plan_item_id}`);
		},
		fillPosition: ({ hiring_plan_id, hiring_plan_item_id, candidate_id, filled_at }) => {
			return axios.put(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item/${hiring_plan_item_id}/fill`, { candidate_id, filled_at });
		},
		unfillPosition: ({ hiring_plan_id, hiring_plan_item_id }) => {
			return axios.put(`/v1/hire-app/hiring-plan/${hiring_plan_id}/hiring-plan-item/${hiring_plan_item_id}/unfill`);
		}
	},
	knownHosts: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/known-hosts/${id}`, { params });
			}
			return axios.get('/v1/hire-app/known-hosts', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/known-hosts', params);
		},
		post(params) {
			return axios.post('/v1/hire-app/known-hosts', params);
		},
	},
	locations: {
		get(params) {
			return axios.get('/v1/hire-app/locations', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/locations', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/locations', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/locations/${id}`, { params });
			}
			return axios.delete('/v1/hire-app/locations', { params });
		},
	},
	mentions: {
		markAsSeen: {
			post(params) {
				return axios.post('/v1/hire-app/mentions/mark-as-seen', params);
			},
		},
	},
	notes: {
		post({ id, ...params }) {
			return axios.post(`/v1/hire-app/candidates/${id}/notes`, params);
		},
	},
	notifications: {
		markAsSeen: {
			post(params) {
				return axios.post('/v1/hire-app/notifications/mark-as-seen', params);
			},
		},
	},
	notificationSettings: {
		get(params) {
			return axios.get('/v1/hire-app/notification-settings', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/notification-settings', params);
		},
	},
	offers: {
		post(params) {
			return axios.post('/v1/hire-app/offers', params);
		},
	},
	offerTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/offer-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/offer-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/offer-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/offer-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/offer-templates', params);
		},
	},
	ogImage: {
		preview: {
			post(params) {
				return axios.post('/v1/og-image/preview', params);
			},
		},
	},
	ogPreference: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/og-preference/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/og-preference', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/og-preference', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/og-preference/${id}`, params);
			}
			return axios.put('/v1/hire-app/og-preference', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/og-preference/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/og-preference', { params });
		},
	},
	organizations: {
		get(params) {
			return axios.get('/v1/hire-app/organizations', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/organizations', params);
		},
		post(params) {
			return axios.post('/v1/hire-app/organizations', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/organizations/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/organizations', { params });
		},
	},
	pipeline: {
		interview: {
			get(params) {
				return axios.get('/v1/hire-app/pipeline/interview', { params });
			},
		},
		archive: {
			get(params) {
				return axios.get('/v1/hire-app/pipeline/archive', { params });
			},
		},
		lead: {
			get(params) {
				return axios.get('/v1/hire-app/pipeline/lead', { params });
			},
		},
	},
	predefinedPositionPresets: {
		get(params) {
			return axios.get('/v1/hire-app/predefined-position-presets', {
				params,
			});
		},
		post(params) {
			return axios.post('/v1/hire-app/predefined-position-presets', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/predefined-position-presets', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/predefined-position-presets/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/predefined-position-presets', {
				params,
			});
		},
	},
	predefinedPositions: {
		get(params) {
			return axios.get('/v1/hire-app/predefined-positions', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/predefined-positions', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/predefined-positions', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/predefined-positions/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/predefined-positions', {
				params,
			});
		},
	},
	reportTemplates: {
		get({ uuid, ...params }) {
			if (uuid) {
				return axios.get(`/v1/hire-app/report-templates/${uuid}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/report-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/report-templates', params);
		},
		put({ uuid, ...params }) {
			if (uuid) {
				return axios.put(`/v1/hire-app/report-templates/${uuid}`, params);
			}
			return axios.put('/v1/hire-app/report-templates', params);
		},
	},
	reports: {
		counters: {
			applicant: {
				get(params) {
					return axios.get('/v1/hire-app/reports/counters/applicant', {
						params,
					});
				},
			},
			archive: {
				get(params) {
					return axios.get('/v1/hire-app/reports/counters/archive', {
						params,
					});
				},
			},
			interview: {
				get(params) {
					return axios.get('/v1/hire-app/reports/counters/interview', {
						params,
					});
				},
			},
			lead: {
				get(params) {
					return axios.get('/v1/hire-app/reports/counters/lead', {
						params,
					});
				},
			},
		},
		candidates: {
			applicant: {
				get(params) {
					return axios.get('/v1/hire-app/reports/candidates/applicant', {
						params,
					});
				},
			},
			archive: {
				get(params) {
					return axios.get('/v1/hire-app/reports/candidates/archive', {
						params,
					});
				},
			},
			interview: {
				get(params) {
					return axios.get('/v1/hire-app/reports/candidates/interview', {
						params,
					});
				},
			},
			lead: {
				get(params) {
					return axios.get('/v1/hire-app/reports/candidates/lead', {
						params,
					});
				},
			},
		},
		durations: {
			interview: {
				get(params) {
					return axios.get('/v1/hire-app/reports/durations/interview', {
						params,
					});
				},
			},
			lead: {
				get(params) {
					return axios.get('/v1/hire-app/reports/durations/lead', {
						params,
					});
				},
			},
			getHired: {
				get(params) {
					return axios.get('/v1/hire-app/reports/durations/get-hired', {
						params,
					});
				},
			},
		},
		interviewers: {
			jobCounts: {
				get(params) {
					return axios.get('/v1/hire-app/reports/interviewers/job-counts', {
						params,
					});
				},
			},
			scoreCounts: {
				get(params) {
					return axios.get('/v1/hire-app/reports/interviewers/score-counts', {
						params,
					});
				},
			},
			interviewerCounts: {
				get(params) {
					return axios.get(
						'/v1/hire-app/reports/interviewers/interviewer-counts',
						{ params },
					);
				},
			},
		},
	},
	requisitionApprovalFlowTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(
					`/v1/hire-app/requisition-approval-flow-templates/${id}`,
					{ params },
				);
			}
			return axios.get('/v1/hire-app/requisition-approval-flow-templates', {
				params,
			});
		},
		post(params) {
			return axios.post(
				'/v1/hire-app/requisition-approval-flow-templates',
				params,
			);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(
					`/v1/hire-app/requisition-approval-flow-templates/${id}`,
					params,
				);
			}
			return axios.put(
				'/v1/hire-app/requisition-approval-flow-templates',
				params,
			);
		},
	},
	requisitionFormTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/requisition-form-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/requisition-form-templates', {
				params,
			});
		},
		post(params) {
			return axios.post('/v1/hire-app/requisition-form-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(
					`/v1/hire-app/requisition-form-templates/${id}`,
					params,
				);
			}
			return axios.put('/v1/hire-app/requisition-form-templates', params);
		},
	},
	requisitions: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/requisitions/${id}`, { params });
			}
			return axios.get('/v1/hire-app/requisitions', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/requisitions', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/requisitions/${id}`, params);
			}
			return axios.put('/v1/hire-app/requisitions', params);
		},
		similar: {
			get(params) {
				return axios.get('/v1/hire-app/requisitions/similar', {
					params,
				});
			},
		},
	},
	requisitionSettings: {
		get(params) {
			return axios.get('/v1/hire-app/requisition-settings', { params });
		},
		put(params) {
			return axios.put('/v1/hire-app/requisition-settings', params);
		},
	},
	scorecards: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/scorecards/${id}`, { params });
			}
			return axios.get('/v1/hire-app/scorecards', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/scorecards', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/scorecards/${id}`, params);
			}
			return axios.put('/v1/hire-app/scorecards', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/scorecards/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/scorecards', { params });
		},
	},
	scorecardTemplates: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/scorecard-templates/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/scorecard-templates', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/scorecard-templates', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/scorecard-templates/${id}`, params);
			}
			return axios.put('/v1/hire-app/scorecard-templates', params);
		},
	},
	search: {
		get(params) {
			return axios.get('/v1/hire-app/search', { params });
		},
	},
	switchAccount: {
		post(params) {
			return axios.post('/v1/hire-app/switch-account', params);
		},
	},
	tags: {
		get(params) {
			return axios.get('/v1/hire-app/tags', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/tags', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/tags', params);
		},
	},
	teams: {
		get(params) {
			return axios.get('/v1/hire-app/teams', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/teams', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/teams', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/teams/${id}`, { params });
			}
			return axios.delete('/v1/hire-app/teams', { params });
		},
	},
	timezones: {
		get(params) {
			return axios.get('/v1/hire-app/timezones', { params });
		},
	},
	userApprovalRequests: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/user-approval-requests/${id}`, {
					params,
				});
			}
			return axios.get('/v1/hire-app/user-approval-requests', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/user-approval-requests', params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/user-approval-requests/${id}`, {
					id,
					...params,
				});
			}
			return axios.put('/v1/hire-app/user-approval-requests', params);
		},
	},
	users: {
		get({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/hire-app/users/${id}`, { params });
			}
			return axios.get('/v1/hire-app/users', { params });
		},
		post(params) {
			return axios.post(`/v1/hire-app/users`, params);
		},
		put({ id, ...params }) {
			if (id) {
				return axios.put(`/v1/hire-app/users/${id}`, params);
			}
			return axios.put(`/v1/hire-app/users`, params);
		},
		reinvite(params) {
			return axios.post(`/v1/hire-app/users/resend-invitation-email`, params);
		},
		clearUsersFirebaseSession(email) {
			return axios.delete('/v1/hire-app/auth/clear-session', {
				data: { agent_mode: true, email },
			});
		},
	},
	workTypes: {
		get(params) {
			return axios.get('/v1/hire-app/work-types', { params });
		},
		post(params) {
			return axios.post('/v1/hire-app/work-types', params);
		},
		put(params) {
			return axios.put('/v1/hire-app/work-types', params);
		},
		delete({ id, ...params }) {
			if (id) {
				return axios.delete(`/v1/hire-app/work-types/${id}`, {
					params,
				});
			}
			return axios.delete('/v1/hire-app/work-types', { params });
		},
	},
	groupMail: {
		get() {
			return axios.get('/v1/hire-app/company/shared-inbox');
		},
		post(params) {
			return axios.post('/v1/hire-app/company/shared-inbox', {
				...params,
			});
		},
		put(params, id) {
			return axios.put(`/v1/hire-app/company/shared-inbox/${id}`, {
				...params,
			});
		},
		revokeConsent(emailAddress, userId) {
			return axios.post(
				`/v1/hire-app/revoke-ms-consent?service=shared_inbox&shared_inbox=${emailAddress}`,
				{ userId },
			);
		},
	},
	hierarchy: {
		get(params) {
			return axios.get('/v1/kolayik/hierarchy', { params });
		},
	},
	searchManager: {
		get(params) {
			return axios.get('/v1/kolayik/person', { params });
		},
	},
	kolay: {
		getByCandidateId({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/kolayik/hiree/${id}`, { params });
			}
		},
		getByKolayId({ id, ...params }) {
			if (id) {
				return axios.get(`/v1/kolayik/person/${id}`, { params });
			}
		},
		put({ id, ...params }) {
			if (id) {
				const { payload } = params;
				return axios.put(`/v1/kolayik/hiree/${id}`, payload);
			}
		},
		post({ id, ...params }) {
			if (id) {
				const { payload } = params;
				return axios.post(`/v1/kolayik/hiree/${id}`, payload);
			}
		},
	},
	revokeMicrosoftCalendarConsent: {
		post(params) {
			return axios.post(
				'/v1/hire-app/revoke-ms-consent?service=calendar',
				params,
			);
		},
	},
	revokeMicrosoftMailConsent: {
		post(params) {
			return axios.post('/v1/hire-app/revoke-ms-consent?service=mail', params);
		},
	},
	emailsConsented: {
		get(params) {
			return axios.get('/v1/hire-app/emails-consented', { params });
		},
	},
};
