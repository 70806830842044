import api from '@/api';

const state = {
    hiringPlan: {
        is_activated: false,
        is_notifications_activated: false,
        seats: [],
    },
    hiringPlanItems: {},
    updated: 0,
    needsToRefresh: 0
};

const getters = {
    getHiringPlan: state => state.hiringPlan,
    getSeats: state => state.hiringPlan.seats,
    getHiringPlanItems: state => hiring_plan_id => state.hiringPlanItems[hiring_plan_id] || []
};

const actions = {
    get: ({ commit }, { id, refresh = false }) => {
        return new Promise((resolve, reject) => {
            const { updated } = state;
            if (!refresh && updated > 0) {
                resolve({ data: state.hiringPlan });
                return;
            }

            api.hiringPlan
                .get({ id })
                .then(response => {
                    if (response.data) {
                        const hiringPlan = response.data.items[0];
                        commit('SET_HIRING_PLAN', {
                            is_activated: hiringPlan.is_activated,
                            is_notifications_activated: hiringPlan.is_notifications_activated,
                            seats: []
                        });
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    getItems: ({ commit }, { hiring_plan_id }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .get({ hiring_plan_id })
                .then(response => {
                    if (response.data && response.data.items) {
                        commit('SET_SEATS', response.data.items);
                        commit('SET_HIRING_PLAN_ITEMS', {
                            hiring_plan_id,
                            items: response.data.items
                        });
                    }
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    post: ({ commit }, params) => {
        return new Promise((resolve, reject) => {
            api.hiringPlan.post(params)
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    put: ({ commit }, { id, ...params }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlan
                .put({ id, ...params })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    addItem: ({ commit }, { hiring_plan_id, items }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .post({ hiring_plan_id, items })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    updateItem: ({ commit }, { hiring_plan_id, hiring_plan_item_id, ...params }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .put({ hiring_plan_id, hiring_plan_item_id, ...params })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    deleteItem: ({ commit }, { hiring_plan_id, hiring_plan_item_id }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .delete({ hiring_plan_id, hiring_plan_item_id })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    fillPosition: ({ commit }, { hiring_plan_id, hiring_plan_item_id, candidate_id, filled_at }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .fillPosition({
                    hiring_plan_id,
                    hiring_plan_item_id,
                    candidate_id,
                    filled_at
                })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    unfillPosition: ({ commit }, { hiring_plan_id, hiring_plan_item_id }) => {
        return new Promise((resolve, reject) => {
            api.hiringPlanItem
                .unfillPosition({ hiring_plan_id, hiring_plan_item_id })
                .then(response => {
                    commit('setNeedsToRefresh');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    setHiringPlan({ commit }, hiringPlan) {
        commit('SET_HIRING_PLAN', hiringPlan);
    },

    updateHiringPlan({ commit }, updates) {
        commit('UPDATE_HIRING_PLAN', updates);
    },

    addSeat({ commit }, seat) {
        commit('ADD_SEAT', seat);
    }
};

const mutations = {
    SET_HIRING_PLAN(state, hiringPlan) {
        state.hiringPlan = hiringPlan;
    },
    UPDATE_HIRING_PLAN(state, updates) {
        state.hiringPlan = { ...state.hiringPlan, ...updates };
    },
    SET_SEATS(state, seats) {
        state.hiringPlan.seats = seats || [];
    },
    ADD_SEAT(state, seat) {
        state.hiringPlan.seats = [...state.hiringPlan.seats, seat];
    },
    setNeedsToRefresh(state) {
        const now = new Date();
        state.needsToRefresh = now.getTime();
        state.updated = now.getTime();
    },
    SET_HIRING_PLAN_ITEMS(state, { hiring_plan_id, items }) {
        state.hiringPlanItems = {
            ...state.hiringPlanItems,
            [hiring_plan_id]: items
        };
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
