<template>
	<v-menu
		ref="menu"
		:close-on-content-click="false"
		:return-value.sync="date"
		transition="scale-transition"
		offset-y
		max-width="290px"
		min-width="290px"
	>
		<template v-slot:activator="{ on }">
			<div v-if="readonly">
				<fieldset class="talentics-fieldset talentics-fieldset-paddings">
					<legend>{{ label }}</legend>
					<div v-html="date ? date : '&nbsp;'"></div>
				</fieldset>
			</div>
			<div v-else>
				<v-btn
					v-if="type === 'button'"
					v-on="on"
					depressed
					color="transparent"
					tile
				>
					{{ formatDate({ timestamp: date, dateFormat }) }}
					<v-icon right>arrow_drop_down</v-icon>
				</v-btn>
				<v-text-field
					v-else
					:value="formatDate({ timestamp: date, dateFormat })"
					:prepend-inner-icon="prependInnerIcon"
					readonly
					v-on="on"
					:outlined="outlined"
					:background-color="`${isDark ? '' : '#ffffff'}`"
					hide-details
					no-title
					:error-messages="errors"
					:label="label"
					:dense="dense"
					:disabled="disabled"
				></v-text-field>
			</div>
		</template>
		<div>
			<v-date-picker
				v-model="date"
				:allowed-dates="allowedDates"
				no-title
				scrollable
				@input="handleDatePickerMenuInput"
				:first-day-of-week="1"
				:range="range"
				:format="dateFormat"
			></v-date-picker>
			<div v-if="range" style="margin-top: -5px">
				<v-card elevation="0">
					<v-card-text>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.quick-selections') }}</legend>
							<div>
								<div>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('this-week')"
									>{{ $t('button.this-week') }}</v-btn>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('last-week')"
									>{{ $t('button.last-week') }}</v-btn>
								</div>
								<v-divider style="margin: .25rem 0;"></v-divider>
								
								<div>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('this-month')"
									>{{ $t('button.this-month') }}</v-btn>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('last-month')"
									>{{ $t('button.last-month') }}</v-btn>
								</div>
								<v-divider style="margin: .25rem 0;"></v-divider>
								
								<div>
									<v-btn
										v-for="(quarter, index) in lastFourQuarters" :key="index"
										x-small
										style="margin: 0.25rem"
										@click="handleCustomQuickSelection(quarter.start, quarter.end)"
									>
										{{ quarter.label }}
									</v-btn>
								</div>
								
								<v-divider style="margin: .25rem 0;"></v-divider>
								
								<div>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('this-year')"
									>{{ $t('button.this-year') }}</v-btn>
									<v-btn
										x-small
										style="margin: 0.25rem"
										@click="handleQuickSelection('last-year')"
									>{{ $t('button.last-year') }}</v-btn>
								</div>
							</div>
						</fieldset>
					</v-card-text>
				</v-card>
			</div>
		</div>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';

export default {
	name: 'DatePicker',
	data() {
		return {
			format,
			date: null,
			menu: false,
		};
	},
	computed: {
		...mapState({
			isDark: state => state.currentUser.isDark,
		}),
		lastFourQuarters() {
			const quarters = [];
			let currentYear = new Date().getFullYear();
			let currentQuarter = Math.floor(new Date().getMonth() / 3) + 1;
			const today = new Date();

			// Exclude the current quarter if it's not over yet
			if (today < new Date(currentYear, (currentQuarter - 1) * 3 + 3, 1)) {
				currentQuarter -= 1;
				if (currentQuarter === 0) {
					currentQuarter = 4;
					currentYear -= 1;
				}
			}

			for (let i = 0; i < 4; i++) {
				const startMonth = (currentQuarter - 1) * 3;
				const start = new Date(currentYear, startMonth, 1);
				const end = new Date(currentYear, startMonth + 3, 0); // Last day of the quarter

				quarters.push({
					label: `${currentYear} Q${currentQuarter}`,
					start: this.format(start, 'yyyy-MM-dd'),
					end: this.format(end, 'yyyy-MM-dd'),
				});

				// Move to the previous quarter
				currentQuarter--;
				if (currentQuarter === 0) {
					currentQuarter = 4;
					currentYear -= 1;
				}
			}

			return quarters;
		},
	},
	props: {
		value: {
			type: [String, Array],
			required: false,
		},
		label: {
			type: String,
			required: false,
			default() {
				return 'Date';
			},
		},
		errors: {
			type: Array,
			required: false,
			default() {
				return [];
			},
		},
		dense: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		firstDayOfWeek: {
			type: Number,
			required: false,
			default() {
				return 1;
			},
		},
		outlined: {
			type: Boolean,
			required: false,
			default() {
				return true;
			},
		},
		prependInnerIcon: {
			type: String,
			required: false,
		},
		type: {
			type: String,
			required: false,
		},
		disabled: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		range: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		readonly: {
			type: Boolean,
			required: false,
			default() {
				return false;
			},
		},
		dateFormat: {
			type: String,
			required: false,
			default() {
				return 'd MMM yyyy';
			},
		},
	},
	methods: {
		handleQuickSelection(preset) {
			const today = new Date();
			let start, end;

			if (preset === 'this-week') {
				const startOfWeek = new Date(today);
				startOfWeek.setDate(today.getDate() - today.getDay());
				start = this.format(startOfWeek, 'yyyy-MM-dd');
				end = this.format(today, 'yyyy-MM-dd');
			} else if (preset === 'last-week') {
				const lastWeekStart = new Date(today);
				lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
				const lastWeekEnd = new Date(lastWeekStart);
				lastWeekEnd.setDate(lastWeekStart.getDate() + 6);
				start = this.format(lastWeekStart, 'yyyy-MM-dd');
				end = this.format(lastWeekEnd, 'yyyy-MM-dd');
			} else if (preset === 'this-month') {
				const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
				start = this.format(startOfMonth, 'yyyy-MM-dd');
				end = this.format(today, 'yyyy-MM-dd');
			} else if (preset === 'last-month') {
				const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
				const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
				start = this.format(startOfLastMonth, 'yyyy-MM-dd');
				end = this.format(endOfLastMonth, 'yyyy-MM-dd');
			} else if (preset === 'this-year') {
				const startOfYear = new Date(today.getFullYear(), 0, 1);
				start = this.format(startOfYear, 'yyyy-MM-dd');
				end = this.format(today, 'yyyy-MM-dd');
			} else if (preset === 'last-year') {
				const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
				const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31);
				start = this.format(startOfLastYear, 'yyyy-MM-dd');
				end = this.format(endOfLastYear, 'yyyy-MM-dd');
			}

			this.$emit('input', [start, end]);
			this.$refs.menu.save([start, end]);
		},
		handleCustomQuickSelection(start, end) {
			this.$emit('input', [start, end]);
			this.$refs.menu.save([start, end]);
		},
		handleMenuSave() {
			const { date, range } = this;
			if (date) {
				if (range) {
					if (date.length === 2) {
						const start_date = new Date(date[0]);
						const end_date = new Date(date[1]);
						const switch_min_max_values = start_date > end_date;
						const min_date = switch_min_max_values ? date[1] : date[0];
						const max_date = switch_min_max_values ? date[0] : date[1];
						const dates = [min_date, max_date];
						this.$emit('input', dates);
						this.$refs.menu.save(dates);
					}
				} else {
					const formattedDate = new Date(date);
					const isoString = formattedDate.toISOString();
					this.$emit('input', isoString);
					this.$refs.menu.save(date);
				}
			}
		},
		allowedDates() {
			return true;
		},
		formatDate({ timestamp, dateFormat = 'dd.MM.yyyy' }) {
			if (!timestamp) return '';
			try {
				const newDate = new Date(timestamp);
				return format(newDate, dateFormat);
			} catch (error) {
				console.error('Error formatting date:', error);
				return timestamp;
			}
		},
		handleDatePickerMenuInput() {
			if (this.range) {
				this.handleMenuSave();
			} else {
				const formattedDate = new Date(this.date);
				const isoString = formattedDate.toISOString();
				this.$emit('input', isoString);
				this.$refs.menu.save(this.date);
			}
		},
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal) {
					if (this.range) {
						this.date = newVal;
					} else {
						const date = new Date(newVal);
						this.date = date.toISOString().substr(0, 10);
					}
				} else {
					this.date = null;
				}
			},
			deep: true,
			immediate: true,
		},
	},
};
</script>
