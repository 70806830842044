<template>
    <div>
        <div class="d-flex" v-if="application.archive_status === 'archive-hired' && currentJob?.hiring_plan_id && application.is_archived">
            <div v-if="hiredPosition" class="d-flex col-12 align-center pa-4 grey lighten-3 rounded">
                <v-icon size="18">mdi-information</v-icon>
                <span class="ml-4">{{ $t('text.candidate-hired-for-position', {
                    date:
                        formatDate(hiredPosition.filled_at), seat_type: $t(`label.${hiredPosition.seat_type}`)
                }) }}</span>
                <v-spacer></v-spacer>

                <v-btn icon small @click="handleEditPosition">
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon small class="ml-2" @click="handleDeleteClick">
                    <v-icon small>mdi-delete</v-icon>
                </v-btn>
            </div>

            <v-btn v-else-if="hasAvailablePositions" @click="handleFillPositionClick" color="primary" small
                class="mt-2 ml-auto">
                <v-icon left>mdi-account-check</v-icon>
                {{ $t('button.fill-position') }}
            </v-btn>

            <FillPositionDialog v-if="showFillPositionDialog" :candidateId="candidate.id" :onClose="handleDialogClose"
                :jobId="jobId" :editMode="editMode" :hiringPlanItemId="selectedHiringPlanItemId" />

            <v-dialog v-model="showDeleteDialog" max-width="400">
                <v-card>
                    <v-card-title>{{ $t('label.confirm-delete') }}</v-card-title>
                    <v-card-text>{{ $t('text.confirm-delete-hired-position') }}</v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="showDeleteDialog = false">
                            {{ $t('button.cancel') }}
                        </v-btn>
                        <v-btn color="error" :loading="isDeleting" @click="handleDeleteConfirm">
                            {{ $t('button.delete') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import FillPositionDialog from './FillPositionDialog.vue';

export default {
    name: 'FillPosition',

    components: {
        FillPositionDialog
    },

    props: {
        jobId: {
            type: [Number, String],
            required: true
        },
        application: {
            type: Object,
            required: true
        },
        candidate: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false,
            showFillPositionDialog: false,
            showDeleteDialog: false,
            isDeleting: false,
            editMode: false,
            selectedHiringPlanItemId: null,
            hiringPlanItems: []
        };
    },

    computed: {
        ...mapState({
            jobs: state => state.jobs.items
        }),

        currentJob() {
            return this.jobs.find(job => job.id === this.jobId);
        },

        hiredPosition() {
            return this.hiringPlanItems.find(item =>
                item.candidate_id === this.candidate.id && item.filled_at && item.status === 'filled'
            );
        },

        hasAvailablePositions() {
            return this.hiringPlanItems.some(item => !item.is_filled);
        }
    },

    methods: {
        formatDate(dateString) {
            if (!dateString) return '';
            return format(new Date(dateString), 'dd.MM.yyyy');
        },

        async fetchHiringPlanItems() {
            if (!this.currentJob?.hiring_plan_id) return;

            this.loading = true;
            try {
                const response = await this.$store.dispatch('hiringPlan/getItems', {
                    hiring_plan_id: this.currentJob.hiring_plan_id,
                    refresh: true
                });

                if (response.data && response.data.items) {
                    this.$set(this, 'hiringPlanItems', response.data.items);
                }
            } catch (error) {
                console.error('Error fetching hiring plan items:', error);
            } finally {
                this.loading = false;
            }
        },

        handleFillPositionClick() {
            this.editMode = false;
            this.selectedHiringPlanItemId = null;
            this.showFillPositionDialog = true;
        },

        handleEditPosition() {
            this.editMode = true;
            this.selectedHiringPlanItemId = this.hiredPosition.id;
            this.showFillPositionDialog = true;
        },

        handleDeleteClick() {
            this.showDeleteDialog = true;
        },

        async handleDeleteConfirm() {
            if (!this.hiredPosition) return;

            this.isDeleting = true;
            try {
                await this.$store.dispatch('hiringPlan/unfillPosition', {
                    hiring_plan_id: this.currentJob.hiring_plan_id,
                    hiring_plan_item_id: this.hiredPosition.id
                });

                this.$store.commit('candidates/setItemNeedsToRefresh');
                await this.fetchHiringPlanItems();
                this.showDeleteDialog = false;
            } catch (error) {
                console.error('Error deleting hired position:', error);
            } finally {
                this.isDeleting = false;
            }
        },

        async handleDialogClose() {
            this.showFillPositionDialog = false;
            await this.fetchHiringPlanItems();
        }
    },

    async created() {
        if (this.application.archive_status === 'archive-hired') {
            await this.fetchHiringPlanItems();
        }
    },

    watch: {
        'application.archive_status': {
            immediate: true,
            async handler(newStatus) {
                if (newStatus === 'archive-hired') {
                    await this.fetchHiringPlanItems();
                }
            }
        },
        'currentJob.hiring_plan_id': {
            immediate: true,
            async handler(newId) {
                if (newId && this.application.archive_status === 'archive-hired') {
                    await this.fetchHiringPlanItems();
                }
            }
        },
        hiringPlanItems: {
            deep: true,
            handler() {
                this.$forceUpdate();
            }
        }
    }
};
</script>