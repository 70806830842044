<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>
        {{ $t('label.fill-position') }}
      </v-card-title>
      <v-card-text>
        <v-select 
          class="mb-4" 
          v-if="selectedJob && hiringPlanId" v-model="selectedPosition" 
          :items="positions"
          :loading="loading" 
          :label="$t('label.select-position')" 
          item-text="position_label" 
          item-value="id"
          hide-no-data 
          hide-details 
          outlined 
          dense 
          :error-messages="errorMessage" 
          :error="!!errorMessage"
        />

        <DatePicker v-model="filledAt" :label="$t('label.filled_at')" outlined dense class="mt-4" dateFormat="dd.MM.yyyy" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="handleClose">
          {{ $t('button.cancel') }}
        </v-btn>
        <v-btn color="primary" :loading="submitting" :disabled="!selectedPosition || !selectedJob || !filledAt"
          @click="handleSubmit">
          {{ $t('button.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import DatePicker from '@/components/shared/datePicker/DatePicker.vue';

export default {
  name: 'FillPositionDialog',
  components: {
    DatePicker
  },
  props: {
    candidateId: {
      type: [Number, String],
      required: true
    },
    onClose: {
      type: Function,
      required: true
    },
    jobId: {
      type: [Number, String],
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    hiringPlanItemId: {
      type: [Number, String],
      default: null
    }
  },
  data() {
    return {
      dialog: true,
      loading: false,
      submitting: false,
      selectedJob: null,
      selectedPosition: null,
      filledAt: null,
      errorMessage: '',
      positions: [],
      hiringPlanId: null
    };
  },
  computed: {
    ...mapState({
      hiringPlan: state => state.hiringPlan.hiringPlan,
      jobs: state => state.jobs.items
    }),
  },
  async mounted() {
    const jobId = this.jobId;
    this.selectedJob = this.jobs.find(job => job.id === jobId);
    this.hiringPlanId = this.selectedJob.hiring_plan_id;

    if (this.editMode && this.hiringPlanItemId) {
      await this.fetchPositions(true);
      this.selectedPosition = this.hiringPlanItemId;
      const item = this.positions.find(p => p.id === this.hiringPlanItemId);
      if (item) {
        this.filledAt = item.filled_at;
      }
    } else {
      await this.fetchPositions();
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return '';
      return format(new Date(dateString), 'dd.MM.yyyy');
    },

    async fetchPositions(includeFilledPosition = false) {
      if (!this.hiringPlanId) return;
      this.loading = true;
      this.errorMessage = '';

      try {
        const response = await this.$store.dispatch('hiringPlan/getItems', {
          hiring_plan_id: this.hiringPlanId,
          refresh: true
        });

        if (response.data && response.data.items) {
          this.positions = response.data.items
            .filter(item => item.status !== "filled" || (includeFilledPosition && item.id === this.hiringPlanItemId))
            .map(item => ({
              id: item.id,
              position_label: `${this.$t(`label.${item.seat_type}`)} - ${item.time_to_fill ? this.formatDate(item.time_to_fill) : ''}`,
              filled_at: item.filled_at
            }));
        }
      } catch (error) {
        this.errorMessage = this.$t('err.an-error-occurred');
        console.error('Error fetching positions:', error);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      if (!this.selectedPosition || !this.hiringPlanId || !this.filledAt) return;

      this.submitting = true;
      this.errorMessage = '';

      try {
        await this.$store.dispatch('hiringPlan/fillPosition', {
          hiring_plan_id: this.hiringPlanId,
          hiring_plan_item_id: this.selectedPosition,
          candidate_id: this.candidateId,
          filled_at: this.filledAt
        });

        this.$store.commit('candidates/setItemNeedsToRefresh');
        this.handleClose();
      } catch (error) {
        this.errorMessage = error.data.error;
        console.error('Error filling position:', error);
      } finally {
        this.submitting = false;
      }
    },

    handleClose() {
      this.dialog = false;
      this.onClose();
    }
  }
};
</script>