<template>
    <div style="max-width: 960px">
        <v-container fluid>
            <v-row>
                <v-col cols="12">
                    <fieldset class="talentics-fieldset talentics-fieldset-paddings">
                        <legend>
                            {{ $t('label.status') }}
                        </legend>
                        <v-switch 
                          v-model="hiringPlan.is_activated"
                          :label="hiringPlan.is_activated ? $t('label.active') : $t('label.inactive')" 
                          class="v-input--template-switch"
                          hide-details
                          inset
                          dense
                        />
                    </fieldset>
                </v-col>

                <v-col cols="12">
                    <fieldset class="talentics-fieldset talentics-fieldset-paddings">
                        <legend>
                            {{ $t('label.reminders') }}
                        </legend>
                        <div class="d-flex align-center">
                            <v-switch v-model="hiringPlan.is_notifications_activated"
                              :label="hiringPlan.is_notifications_activated ? $t('label.open') : $t('label.close')"
                              disabled
                              :value="false" 
                              class="v-input--template-switch"
                              hide-details
                              inset
                              dense
                            />
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon 
                                        small 
                                        color="grey" 
                                        class="ml-2"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-information
                                    </v-icon>
                                </template>
                                <span>{{ $t('text.reminders-feature-coming-soon') }}</span>
                            </v-tooltip>
                        </div>
                    </fieldset>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <fieldset class="talentics-fieldset talentics-fieldset-paddings">
                        <legend>
                            {{ $t('label.seats') }}
                        </legend>
                        <div v-if="hiringPlan.seats && hiringPlan.seats.length > 0">
                            <div v-for="(seat, displayIndex) in hiringPlan.seats.filter(s => !s.isDeleted)" :key="seat.id || displayIndex">
                                <v-row align="center">
                                    <v-col cols="5">
                                        <v-row align="center" no-gutters>
                                            <v-col cols="1">
                                                <v-avatar
                                                  color="secondary"
                                                  size="24"
                                                >
                                                  <span class="white--text">{{ displayIndex + 1 }}</span>
                                                </v-avatar>
                                            </v-col>
                                            <v-col cols="11">
                                                <v-select outlined hide-details dense v-model="seat.seat_type"
                                                    :items="seatTypes" :label="$t('label.seat_type')"
                                                    @change="value => handleSeatChange(seat, 'seat_type', value)"
                                                    :error="!seat.seat_type"
                                                    :error-messages="!seat.seat_type ? $t('validation.required') : ''"
                                                    :disabled="seat.status === 'filled'"
                                                    required />
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="4">
                                        <DatePicker 
                                            outlined hide-details 
                                            dense 
                                            :value="seat.time_to_fill"
                                            :label="$t('label.time_to_hire')"
                                            @input="value => handleSeatChange(seat, 'time_to_fill', value)"
                                            :error="!seat.time_to_fill"
                                            :error-messages="!seat.time_to_fill ? $t('validation.required') : ''"
                                            :disabled="seat.status === 'filled'"
                                            required 
                                            dateFormat="dd.MM.yyyy"
                                        />
                                    </v-col>

                                    <v-col cols="2">
                                        <v-select outlined hide-details dense :value="seat.status || 'open'"
                                            :items="statusTypes" :label="$t('label.status')" disabled />
                                    </v-col>

                                    <v-col cols="1" class="d-flex justify-end">
                                        <div class="d-flex">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-btn icon @click="handleViewCandidate(seat)"
                                                            :disabled="seat.status !== 'filled'"
                                                            :color="seat.status === 'filled' ? 'primary' : ''"
                                                            class="mr-1"
                                                        >
                                                            <v-icon>mdi-eye</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </template>
                                                <span v-if="seat.status !== 'filled'">{{ $t('text.no-candidate-to-view') }}</span>
                                                <span v-else>{{ $t('button.view-candidate') }}</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <span v-bind="attrs" v-on="on">
                                                        <v-btn icon color="error" @click="deleteSeat(seat)"
                                                            :disabled="seat.status === 'filled'"
                                                        >
                                                            <v-icon>mdi-delete</v-icon>
                                                        </v-btn>
                                                    </span>
                                                </template>
                                                <span v-if="seat.status === 'filled'">{{ $t('text.cannot-delete-filled-seat') }}</span>
                                                <span v-if="seat.status === 'open'">{{ $t('button.delete') }}</span>
                                            </v-tooltip>
                                        </div>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <div v-else class="no-seats-message">
                            {{ $t('text.no-seats') }}
                        </div>

                        <v-divider style="margin-top: 1rem;"></v-divider>

                        <v-btn color="primary" class="mt-4" @click="addSeat">
                            {{ $t('button.add_seat') }}
                        </v-btn>
                    </fieldset>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { format } from 'date-fns';
import DatePicker from '@/components/shared/datePicker/DatePicker.vue';

export default {
    name: 'HiringPlan',
    components: {
        DatePicker
    },
    props: {
        job: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapState({
            company: state => state.company.company,
            hiringPlan: state => state.hiringPlan.hiringPlan,
        }),
    },
    data() {
        return {
            dateMenus: [],
            seatTypes: [
                { text: this.$t('label.new-position'), value: 'new_position' },
                { text: this.$t('label.replacement'), value: 'replacement' },
            ],
            statusTypes: [
                { text: this.$t('label.open'), value: 'open' },
                { text: this.$t('label.filled'), value: 'filled' }
            ],
            newSeats: []
        };
    },
    created() {
        // Initialize the hiring plan with default values if not already set
        if (!this.hiringPlan || !this.hiringPlan.seats) {
            this.$store.dispatch('hiringPlan/setHiringPlan', {
                is_activated: false,
                is_notifications_activated: false,
                seats: [],
            });
        } else {
            // Force is_notifications_activated to false
            this.$store.dispatch('hiringPlan/updateHiringPlan', {
                is_notifications_activated: false
            });
        }
    },
    methods: {
        ...mapActions('hiringPlan', {
            addSeatToStore: 'addSeat',
            updateHiringPlanSeats: 'updateHiringPlan'
        }),

        getCurrentDate() {
            return format(new Date(), 'yyyy-MM-dd');
        },

        addSeat() {
            const newSeat = {
                seat_type: '',
                time_to_fill: null,
                status: 'open',
                isNew: true
            };

            // Create a new array with the existing new seats plus the new one
            const updatedNewSeats = [...this.newSeats, newSeat];
            this.newSeats = updatedNewSeats;

            // Update the hiring plan seats by combining existing seats with all new seats
            const existingSeats = (this.hiringPlan.seats || []).filter(seat => !seat.isNew);
            this.updateHiringPlanSeats({ 
                seats: [...existingSeats, ...updatedNewSeats]
            });
        },

        deleteSeat(seatToDelete) {
            if (seatToDelete.isNew) {
                // Remove from newSeats array
                this.newSeats = this.newSeats.filter(s => s !== seatToDelete);
                
                // Update hiring plan seats
                const existingSeats = (this.hiringPlan.seats || []).filter(seat => !seat.isNew);
                this.updateHiringPlanSeats({ 
                    seats: [...existingSeats, ...this.newSeats]
                });
            } else {
                // Mark existing seat as deleted
                const updatedSeats = this.hiringPlan.seats.map(seat => 
                    seat === seatToDelete ? { ...seat, isDeleted: true } : seat
                );
                this.updateHiringPlanSeats({ seats: updatedSeats });
            }
        },

        handleSeatChange(seat, field, value) {
            if (seat.isNew) {
                // Update in newSeats array
                this.newSeats = this.newSeats.map(s => 
                    s === seat ? { ...s, [field]: value } : s
                );
                
                // Update hiring plan seats
                const existingSeats = (this.hiringPlan.seats || []).filter(seat => !seat.isNew);
                this.updateHiringPlanSeats({ 
                    seats: [...existingSeats, ...this.newSeats]
                });
            } else {
                // Update existing seat
                const updatedSeats = this.hiringPlan.seats.map(s => 
                    s === seat ? { ...s, [field]: value } : s
                );
                this.updateHiringPlanSeats({ seats: updatedSeats });
            }
        },

        validateSeats() {
            if (!this.hiringPlan.seats || this.hiringPlan.seats.length === 0) return true;
            
            return this.hiringPlan.seats
                .filter(s => !s.isDeleted)
                .every(seat => seat.seat_type && seat.time_to_fill);
        },

        handleViewCandidate(seat) {
            if (seat.status === 'filled' && seat.candidate_id) {
                this.$router.push(`/${this.$store.state.currentUser.lang}/candidates/edit/${seat.candidate_id}`);
            }
        },
    },
    watch: {
        'hiringPlan.is_activated': function (newVal) {
            this.$store.dispatch('hiringPlan/updateHiringPlan', {
                is_activated: newVal
            });
        },
        'hiringPlan.is_notifications_activated': function (newVal) {
            this.$store.dispatch('hiringPlan/updateHiringPlan', {
                is_notifications_activated: newVal
            });
        },
        'job.id': {
            immediate: true,
            async handler() {
                if (this.job.hiring_plan_id) {
                    try {
                        await this.$store.dispatch('hiringPlan/get', {
                            id: this.job.hiring_plan_id,
                            refresh: true
                        });

                        await this.$store.dispatch('hiringPlan/getItems', {
                            hiring_plan_id: this.job.hiring_plan_id,
                            refresh: true
                        });
                    } catch (error) {
                        console.error('Error fetching hiring plan:', error);
                    }
                }
            }
        }
    },
    beforeDestroy() {
        this.$store.commit('hiringPlan/SET_HIRING_PLAN', {
            is_activated: false,
            is_notifications_activated: false,
            seats: []
        });
    },
};
</script>

<style scoped>
.no-seats-message {
    text-align: center;
    color: var(--v-medium-emphasis-base);
    padding: 2rem 0;
    font-style: italic;
}
</style>