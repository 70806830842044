<template>
  <fieldset class="talentics-fieldset">
    <legend>{{ $t('text.checkboxes') }}</legend>
    <v-container style="padding-top: 0">
      <Draggable
        v-model="checkboxItems"
				@start="handleDragStart"
				@end="handleDragEnd"
      >
        <div
          v-for="(item, index) in checkboxItems"
          :key="`checkboxItem_${index}`"
        >
          <v-row>
            <v-col cols="auto" align-self="center">
              <span class="all-scroll">
                <v-icon>drag_indicator</v-icon>
              </span>
            </v-col>
            <v-col align-self="center">
              <div style="margin-bottom: 0.5rem">
                <v-text-field
                  v-model="item.label"
                  outlined
                  hide-details
                  dense
                  :placeholder="$t('label.checkbox-label')"
                  @input="handleValueChange"
                ></v-text-field>
              </div>
              <RichEditor
                v-model="item.html"
                refName="`checkbox_${index}`"
                @input="handleValueChange"
              ></RichEditor>
            </v-col>
            <v-col
              class="disp-flex flex-end"
              cols="auto"
              align-self="center"
            >
              <v-btn
                text
                icon
                @click="() =>handleDeleteCheckbox({ index })"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </Draggable>
      <v-row style="margin-top: 0.5rem">
        <v-col>
          <v-btn @click="handleAddNewCheckbox">
            <v-icon left>add</v-icon>
            {{ $t('button.add') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </fieldset>
</template>

<script>
import Draggable from 'vuedraggable';
import RichEditor from '@/components/shared/richEditor/RichEditor';

export default {
	name: 'CheckboxesEditor',
  components: {
		Draggable,
    RichEditor,
  },
	props: {
    value: {
      type: Array,
      required: true,
      default() { return [] },
    },
  },
  data() {
    return {
      checkboxItems: [],
      drag: false,
    };
  },
  methods: {
		handleDragStart() {
			this.drag = true;
		},
		handleDragEnd() {
			this.drag = false;
			this.$emit('input', this.checkboxItems);
		},
		handleDeleteCheckbox({ index }) {
			const newVal = this.checkboxItems.filter(
				(item, itemIndex) => itemIndex !== index,
			);
      this.$emit('input', newVal);
		},
		handleAddNewCheckbox() {
			const newVal = [
				...this.checkboxItems,
				{ label: '', html: '', required: true },
			];
      this.$emit('input', newVal);
		},
    handleValueChange() {
      this.$emit('input', this.checkboxItems);
    },
  },
  watch: {
		value: {
			handler: function (newVal, oldVal) {
				if (
          (newVal && !oldVal) ||
          (newVal && oldVal && (JSON.stringify(newVal) !== JSON.stringify(oldVal)))
        ) {
          this.checkboxItems = [...JSON.parse(JSON.stringify(newVal))];
				}
			},
			immediate: true,
			deep: true,
		},
  }
}
</script>
