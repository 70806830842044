<template>
	<div>
		<div
			v-if="
				(candidate.current_company && candidate.current_company.length > 0) ||
				(candidate.last_school && candidate.last_school.length > 0) ||
				(candidate.tags && candidate.tags.length > 0) ||
				(candidate.total_years_experience &&
					candidate.total_years_experience > 0) ||
				(candidate.work_experience && candidate.work_experience.length > 0) ||
				(candidate.education && candidate.education.length > 0)
			"
		>
			<v-list>
				<v-list-item
					two-line
					v-if="
						candidate.current_company && candidate.current_company.length > 0
					"
				>
					<v-list-item-icon>
						<v-icon>business</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-subtitle>{{
							$t('label.current-company')
						}}</v-list-item-subtitle>
						<v-list-item-title>{{
							candidate.current_company
						}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				
				<v-list-item
				two-line
				v-if="candidate.last_school && candidate.last_school.length > 0"
				>
				<v-list-item-icon>
					<v-icon>school</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
						<v-list-item-subtitle class="overview-subtitle">{{
							toLocaleCapitalize($t('label.last-school-attended'), locale)
						}}</v-list-item-subtitle>
						<v-list-item-title>{{ candidate.last_school }}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					two-line
					v-if="candidate.tags && candidate.tags.length > 0"
				>
					<v-list-item-icon>
						<v-icon>label</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-subtitle>{{ toLocaleCapitalize($t('label.tags'), locale) }}</v-list-item-subtitle>
						<v-list-item-title>
							<ChipEditor v-model="candidate.tags"></ChipEditor>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					v-if="
						candidate.total_years_experience &&
						candidate.total_years_experience > 0
					"
				>
					<v-list-item-icon>
						<v-icon>timer</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-subtitle>{{
							toLocaleCapitalize($t('label.total-years-experience'), locale)
						}}</v-list-item-subtitle>
						<v-list-item-title>
							{{ candidate.total_years_experience }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					two-line
					v-if="
						candidate.work_experience &&
						candidate.work_experience.length > 0 &&
						showMore
					"
				>
					<v-list-item-icon>
						<v-icon>business</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.work-experience') }}</legend>
							<v-row
								v-for="(
									work_experience_item, index
								) in candidate.work_experience"
								:key="`workExperience_${index}`"
							>
								<v-col cols="12">
									<div style="font-weight: 700">
										{{ work_experience_item.job_title }}
									</div>
									<div>
										{{ work_experience_item.organization }}
									</div>
									<div
										style="font-size: 0.75rem"
										v-if="
											work_experience_item.start_date ||
											work_experience_item.end_date
										"
									>
										<span>{{
											work_experience_item.start_date
												? formatDate({
														timestamp: work_experience_item.start_date,
														format: 'MMM yyyy',
												  })
												: ''
										}}</span>
										<span> - </span>
										<span>
											<span v-if="work_experience_item.is_current">
												Present
											</span>
											<span v-else>
												{{
													work_experience_item.end_date
														? formatDate({
																timestamp: work_experience_item.end_date,
																format: 'MMM yyyy',
														  })
														: ''
												}}
											</span>
										</span>
									</div>
									<div>
										{{ work_experience_item.job_description }}
									</div>
								</v-col>
								<v-col
									cols="12"
									v-if="index < candidate.work_experience.length - 1"
								>
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</fieldset>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					two-line
					v-if="
						candidate.education && candidate.education.length > 0 && showMore
					"
				>
					<v-list-item-icon>
						<v-icon>school</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<fieldset class="talentics-fieldset talentics-fieldset-paddings">
							<legend>{{ $t('label.education') }}</legend>
							<v-row
								v-for="(educationItem, index) in candidate.education"
								:key="`education_${index}`"
							>
								<v-col cols="12">
									<div style="font-weight: 700">
										{{ educationItem.organization }}
									</div>
									<div>{{ educationItem.education }}</div>
									<div
										style="font-size: 0.75rem"
										v-if="educationItem.start_date || educationItem.end_date"
									>
										<span>{{
											educationItem.start_date
												? formatDate({
														timestamp: educationItem.start_date,
														format: 'MMM yyyy',
												  })
												: ''
										}}</span>
										<span> - </span>
										<span>{{
											educationItem.end_date
												? formatDate({
														timestamp: educationItem.end_date,
														format: 'MMM yyyy',
												  })
												: ''
										}}</span>
									</div>
								</v-col>
								<v-col cols="12" v-if="index < candidate.education.length - 1">
									<v-divider></v-divider>
								</v-col>
							</v-row>
						</fieldset>
					</v-list-item-content>
				</v-list-item>

				<v-list-item
					v-if="
						(candidate.work_experience &&
							candidate.work_experience.length > 0) ||
						(candidate.education && candidate.education.length > 0)
					"
				>
					<v-list-item-icon> </v-list-item-icon>
					<v-list-item-content>
						<v-btn small @click="showMore = true" v-if="!showMore">
							<v-icon left>expand_more</v-icon>
							{{ $t('button.more') }}
						</v-btn>
						<v-btn small @click="showMore = false" v-else>
							<v-icon left>expand_less</v-icon>
							{{ $t('button.less') }}
						</v-btn>
					</v-list-item-content>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>
		</div>
		<div style="margin-bottom: 1rem; margin-top: 1rem">
			<NewJobMenu :candidateId="candidate.id"></NewJobMenu>
			<v-btn
				v-if="currentUser && currentUser.can_schedule_calendar_events"
				small
				style="margin-left: 0.5rem; margin-right: 0.5rem"
				@click="handleScheduleCandidateDialogOpen"
			>
				<v-icon left>event</v-icon>
				{{ $t('button.schedule-interview') }}
			</v-btn>
			<ScheduleEmailMenu
				:candidate="candidate"
				:onStart="handleScheduleNewEmail"
				buttonIcon="email"
			></ScheduleEmailMenu>
			<v-btn
				small
				style="margin-left: 0.5rem"
				@click="handleSendOfferClick"
				v-if="company.id === 8 && currentUser.can_create_offer_requisitions"
			>
				<v-icon left>receipt_long</v-icon>
				{{ $t('button.send-offer') }}
			</v-btn>
		</div>
		<v-divider></v-divider>
		<v-divider></v-divider>
		<v-subheader>{{ toLocaleCapitalize($t('subheader.applied-jobs'), locale) }}</v-subheader>
		<v-divider></v-divider>
		<div
			v-for="(application, i) in candidate.applications"
			:key="`application_${i}`"
		>
			<div>
				<v-container>
					<v-row>
						<v-col cols="auto" align-self="center">
							<Avatar
								:userId="application.owner"
								:tooltipAppend="`${$t('label.posting-owner')}: `"
							></Avatar>
						</v-col>
						<v-col>
							<div>
								<strong style="font-size: 1.1rem; margin-right: 0.25rem"
									>{{
										getJobName({
											jobId: application.job_id,
										})
									}}
									<span v-if="application.score"
										>({{ parseInt(application.score * 100) }}%)</span
									></strong
								>
								<EditJobMenu
									:candidateId="candidate.id"
									:jobId="application.job_id"
									:applicationId="application.id"
									:refName="`edit_candidate_job_${i}_form`"
								></EditJobMenu>
							</div>
							<div>
								{{
									formatDate({
										timestamp: application.created,
									})
								}}
							</div>
							<div v-if="application.source">
								{{ $t('label.source') }} {{ application.source }}
							</div>
							<div
								v-if="
									(application.feedbacks && application.feedbacks.length > 0) ||
									(application.scorecards && application.scorecards.length > 0)
								"
							>
								{{ $t('subheader.received-feedbacks') }}<br />
								<span style="cursor: pointer">
									<CandidateFeedbackIcons
										:feedbacks="
											application.feedbacks && application.feedbacks.length > 0
												? application.feedbacks
												: []
										"
										:scorecards="
											application.scorecards &&
											application.scorecards.length > 0
												? application.scorecards
												: []
										"
										:onClickItem="onGoToFeedbacks"
									></CandidateFeedbackIcons>
								</span>
							</div>
						</v-col>
						<v-col cols="auto" align-self="center">
							<EditApplicationStatus
								:value="handleGetApplicationStatus(application)"
								:application-id="application.id"
								:job-id="application.job_id"
								:candidate-id="candidate.id"
								:is-lead="application.is_lead"
								:is-interview="application.is_interview"
								:is-archived="application.is_archived"
								:on-archive="onArchiveCandidateDialogOpen"
								:candidate="candidate"
								@change="handleApplicationStatusChange"
							></EditApplicationStatus>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<div style="padding-left: 55px">
								<FillPosition
									:jobId="application.job_id"
									:candidate="candidate"
									:application="application"
								></FillPosition>
							</div>
						</v-col>
					</v-row>
					<v-row
						v-if="
							application.cover_letter && application.cover_letter.length > 0
						"
					>
						<v-col>
							<div style="padding-left: 55px">
								<v-alert
									color="info"
									outlined
									icon="speaker_notes"
									style="margin-bottom: 0"
								>
									<div v-html="application.cover_letter"></div>
								</v-alert>
							</div>
						</v-col>
					</v-row>
					<v-row
						v-if="
							application.link_questions &&
							application.link_questions.length > 0
						"
					>
						<v-col>
							<div style="padding-left: 55px">
								<AdditionalQuestions
									:questionSet="application.link_questions_set"
									:answerSet="application.link_questions"
								></AdditionalQuestions>
							</div>
						</v-col>
					</v-row>
					<v-row v-if="false">
						<v-col>
							<div style="padding-left: 55px">
								<v-divider></v-divider>
								<v-btn
									small
									style="margin-top: 1rem"
									@click="
										() =>
											handleTogglePipelineDurations({
												application_id: application.id,
											})
									"
								>
									<v-icon left>pending</v-icon>
									{{ $t('label.pipeline-durations') }}
									<v-icon right
										>{{
											application.is_pipeline_durations_visible
												? 'arrow_drop_down'
												: 'arrow_right'
										}}
									</v-icon>
								</v-btn>
								<div
									v-if="application.is_pipeline_durations_visible"
									style="margin-top: 0.5rem"
								>
									<PipelineDurations
										:applicationId="application.id"
										:candidateId="candidate.id"
									></PipelineDurations>
								</div>
							</div>
						</v-col>
					</v-row>
					<v-row v-if="application.events && application.events.length > 0">
						<v-col>
							<div style="padding-left: 55px">
								<v-divider></v-divider>
								<v-btn
									small
									style="margin-top: 1rem"
									@click="handleToggleInterviews"
								>
									<v-icon left>event</v-icon>
									{{ $t('label.interviews') }} ({{ application.events.length }})
									<v-icon right
										>{{
											isInterviewsCardVisible
												? 'arrow_drop_down'
												: 'arrow_right'
										}}
									</v-icon>
								</v-btn>
								<v-card
									style="margin-top: 0.5rem"
									v-if="isInterviewsCardVisible"
								>
									<v-container>
										<div
											v-for="(item, index) in application.events"
											:key="`application_${index}`"
										>
											<v-row>
												<v-col align-self="center">
													<div>
														<strong
															>{{
																formatDate({
																	timestamp: item.start_datetime,
																	format: 'dd.MM.yyyy',
																})
															}}
															{{
																formatDate({
																	timestamp: item.start_datetime,
																	format: 'HH:mm',
																})
															}}
															-
															{{
																formatDate({
																	timestamp: item.end_datetime,
																	format: 'HH:mm',
																})
															}}</strong
														>
													</div>
													<div>
														{{ item.summary }}
													</div>
													<div style="margin-top: 0.5rem">
														<UserChips :userIds="item.interviewers"></UserChips>
													</div>
												</v-col>
											</v-row>
											<v-row v-if="index < application.events.length - 1">
												<v-col>
													<v-divider></v-divider>
												</v-col>
											</v-row>
										</div>
									</v-container>
								</v-card>
							</div>
						</v-col>
					</v-row>
					<v-row
						v-if="application.hired_info && application.hired_info.length > 0"
					>
						<v-col>
							<div style="padding-left: 55px">
								<v-divider></v-divider>
								<div style="margin-top: 1rem">
									<v-row
										v-for="(item, index) in application.hired_info"
										:key="`hired_info_${index}`"
									>
										<v-col>
											<fieldset
												class="talentics-fieldset talentics-fieldset-paddings"
											>
												<legend>
													{{ $t('label.hiree-info') }}
												</legend>
												<v-row>
													<v-col>
														<label
															class="v-label v-label--active"
															style="font-size: 12px"
															>{{ $t('label.time-to-hire') }}</label
														>
														<div>
															{{ item.time_to_hire }}
															days
														</div>
													</v-col>
												</v-row>
												<v-divider
													style="margin-top: 0.5rem; margin-bottom: 0.5rem"
												></v-divider>
												<v-row>
													<v-col>
														<v-btn
															small
															@click="
																handleHireeInfoClick({
																	hireeInfoId: item.id,
																})
															"
														>
															view and edit details
															<v-icon right>launch</v-icon>
														</v-btn>
													</v-col>
												</v-row>
											</fieldset>
										</v-col>
									</v-row>
								</div>
							</div>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<CandidateApplicationSteps :application="application" />
							<v-divider v-if="i < candidate.applications.length - 1" class="my-4"></v-divider>
						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>
		<ScheduleCandidateDialog
			v-if="isScheduleCandidateDialogVisible"
			:candidateId="candidate.id"
			:onClose="handleScheduleCandidateDialogClose"
			:onSuccess="handleScheduleCandidateDialogSuccess"
		></ScheduleCandidateDialog>
		<ScheduleEmailDialog
			v-if="isScheduleEmailDialogVisible"
			:email="email"
			:candidate="candidate"
			:onClose="handleScheduleEmailDialogClose"
		></ScheduleEmailDialog>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { format } from 'date-fns';
import { toLocaleCapitalize } from '@/utils/helpers';
import Avatar from '@/components/shared/avatar/Avatar';
import CandidateFeedbackIcons from '../../candidateFeedbackIcons/CandidateFeedbackIcons';
import EditApplicationStatus from '../editApplicationStatus/EditApplicationStatus';
import NewJobMenu from './newJobMenu/NewJobMenu';
import EditJobMenu from './editJobMenu/EditJobMenu';
import UserChips from '@/components/shared/userChips/UserChips';
import ScheduleCandidateDialog from '@/components/routes/candidates/scheduleCandidateDialog/ScheduleCandidateDialog';
import ScheduleEmailDialog from '../emails/scheduleEmailDialog/ScheduleEmailDialog';
import ScheduleEmailMenu from '../emails/scheduleEmailMenu/ScheduleEmailMenu';
import ChipEditor from '@/components/shared/chipEditor/ChipEditor';
import PipelineDurations from '@/components/shared/pipelineDurations/PipelineDurations';
import AdditionalQuestions from './additionalQuestions/AdditionalQuestions';
import CandidateApplicationSteps from '../steps/CandidateApplicationSteps';
import FillPosition from '../fillPosition/FillPosition.vue';

export default {
	name: 'Overview',
	props: {
		candidate: {
			type: Object,
			required: true,
		},
		onArchiveCandidateDialogOpen: {
			type: Function,
			required: true,
		},
		onGoToFeedbacks: {
			type: Function,
			required: true,
		},
	},
	components: {
		Avatar,
		CandidateFeedbackIcons,
		EditApplicationStatus,
		NewJobMenu,
		EditJobMenu,
		UserChips,
		ScheduleCandidateDialog,
		ScheduleEmailDialog,
		ScheduleEmailMenu,
		ChipEditor,
		PipelineDurations,
		AdditionalQuestions,
		CandidateApplicationSteps,
		FillPosition
	},
	computed: {
		...mapState({
			jobs: state => state.jobs.items,
			tags: state => state.tags.items,
			currentUser: state => state.currentUser.currentUser,
			currentLang: state => state.currentUser.lang,
			company: state => state.company.company,
		}),
		locale() {
			return this.currentLang === 'tr' ? 'tr-TR' : 'en-US';
		}
	},
	data() {
		return {
			format,
			toLocaleCapitalize,
			isScheduleCandidateDialogVisible: false,
			isInterviewsCardVisible: false,
			showMore: false,
			isScheduleEmailDialogVisible: false,
			email: null,
		};
	},
	methods: {
		getJobName({ jobId }) {
			const { jobs } = this;
			const job = jobs.find(item => item.id === jobId);
			return job ? job.name : jobId;
		},
		handleGetApplicationStatus({
			is_interview,
			interview_status,
			is_lead,
			lead_status,
			is_archived,
			archive_status,
		}) {
			if (is_archived) {
				return archive_status ? archive_status : 'underqualified';
			} else if (is_interview) {
				return interview_status ? interview_status : 'phone';
			} else if (is_lead) {
				return lead_status ? lead_status : 'new-lead';
			}
			return 'new-applicant';
		},
		handleApplicationStatusChange() {
			this.$store.commit('candidates/setItemNeedsToRefresh');
			this.$store.commit('candidates/setItemsNeedToRefresh');
		},
		formatDate({ timestamp = new Date(), format = 'dd.MM.yyyy HH:mm:ss' }) {
			const dateToFormat = new Date(timestamp);
			return this.format(dateToFormat, format);
		},
		handleScheduleCandidateDialogOpen() {
			this.isScheduleCandidateDialogVisible = true;
		},
		handleScheduleCandidateDialogClose() {
			this.isScheduleCandidateDialogVisible = false;
		},
		handleScheduleCandidateDialogSuccess() {
			this.isScheduleCandidateDialogVisible = false;
			this.$store.commit('candidates/setItemNeedsToRefresh');
		},
		handleToggleInterviews() {
			this.isInterviewsCardVisible = !this.isInterviewsCardVisible;
		},
		handleScheduleNewEmail({ email }) {
			this.email = email;
			this.isScheduleEmailDialogVisible = true;
		},
		handleScheduleEmailDialogClose() {
			this.isScheduleEmailDialogVisible = false;
		},
		handleTogglePipelineDurations({ application_id }) {
			this.candidate = {
				...this.candidate,
				applications: this.candidate.applications.map(applicationItem => {
					if (applicationItem.id === application_id) {
						return {
							...applicationItem,
							is_pipeline_durations_visible:
								!applicationItem.is_pipeline_durations_visible,
						};
					}
					return {
						...applicationItem,
					};
				}),
			};
		},
		handleSendOfferClick() {
			const { id } = this.candidate;
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}/requisitions/new`,
				query,
			});
		},
		handleHireeInfoClick({ hireeInfoId }) {
			const { id } = this.candidate;
			const { currentLang } = this;
			const { query } = this.$route;
			this.$router.push({
				path: `/${currentLang}/candidates/edit/${id}/hiree-info/${hireeInfoId}`,
				query,
			});
		},
	},
};
</script>